<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">Методы оплаты</v-toolbar-title>
    </v-toolbar>
    <v-row no-gutters class="justify-end ma-4">
      <new-pay/>
    </v-row>
    <v-row dense>
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-space-around">
        <span class="caption">enabled</span>
        <span class="caption">disabled</span>
        <span class="caption">selected</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center hidden-xs-only">
        <span class="text-caption">Название</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center hidden-xs-only">
        <span class="text-caption">Описание</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center hidden-xs-only">
        <span class="text-caption pr-1">Приоритет</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-center hidden-xs-only">
        <span class="text-caption">Активный</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center hidden-xs-only">
        <span class="text-caption">Редактировать</span>
      </v-col>
    </v-row>

    <v-row :class="$vuetify.breakpoint.xs ? 'pa-2 mx-1 mb-1' : 'ma-1'" no-gutters class="py-1 grey lighten-4" v-for="(pay, index) in list" :key="pay.id">
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-space-around d-flex align-center">
        <v-avatar size="48px">
          <img alt="" v-if="pay.enabled_icon" :src="pay.enabled_icon" @click="$refs.enabled[index].click()"/>
          <img alt="" v-else
               src="/payment_placeholder.png"
               @click="$refs.enabled[index].click()"/>
        </v-avatar>
        <div v-show=false>
          <input id="enabled"
                 ref="enabled"
                 accept="image/*"
                 type="file"
                 @change="handleEnabledUpload(index, pay)"/>
        </div>
        <v-avatar size="48px">
          <img alt="" v-if="pay.disabled_icon" :src="pay.disabled_icon" @click="$refs.disabled[index].click()"/>
          <img alt="" v-else
               src="/payment_placeholder.png"
               @click="$refs.disabled[index].click()"/>
        </v-avatar>
        <div v-show=false>
          <input id="disabled"
                 ref="disabled"
                 accept="image/*"
                 type="file"
                 @change="handleDisabledUpload(index, pay)"/>
        </div>
        <v-avatar size="48px">
          <img alt="" v-if="pay.selected_icon" :src="pay.selected_icon" @click="$refs.selected[index].click()"/>
          <img alt="" v-else
               src="/payment_placeholder.png"
               @click="$refs.selected[index].click()"/>
        </v-avatar>
        <div v-show=false>
          <input id="selected"
                 ref="selected"
                 accept="image/*"
                 type="file"
                 @change="handleSelectedUpload(index, pay)"/>
        </div>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Название:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ pay.title }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Описание:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1 text-end' : ''" class="text-body-2">{{ pay.subtitle }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Приоритет:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ pay.priority }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Активный:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center d-flex align-center">
        <v-checkbox v-model="pay.active" hide-details class="ml-4 my-0 pa-0" @change="save(pay)"/>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'd-flex align-center' : ''" class="caption">Редактировать:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around d-flex align-center py-1">
        <edit :payment="pay"/>
        <v-btn fab :small="$vuetify.breakpoint.mdAndUp" :x-small="$vuetify.breakpoint.xsOnly" @click="del(pay.id)">
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import edit from "./edit.vue";
import newPay from "./new.vue";

export default {
  components: {edit, newPay},
  data() {
    return {
      dialog: false
    }
  },
  computed: {
    list() {
      return this.$store.getters["payments/list"]
    },
    count() {
      return this.$store.getters["payments/count"]
    }
  },

  created() {
    this.$store.dispatch("payments/fetch", {offset: 0, limit: 20});
  },

  methods: {
    save(pay) {
      this.$store.dispatch("payments/update", pay);
    },

    handleEnabledUpload(index, p) {
      let file = this.$refs.enabled[index].files[0];
      if (file) {
        this.$store.dispatch("payments/enabledUpload", {id: p.id, file: file});
      }
    },
    handleDisabledUpload(index, p) {
      let file = this.$refs.disabled[index].files[0];
      if (file) {
        this.$store.dispatch("payments/disabledUpload", {id: p.id, file: file});
      }
    },
    handleSelectedUpload(index, p) {
      let file = this.$refs.selected[index].files[0];
      if (file) {
        this.$store.dispatch("payments/selectedUpload", {id: p.id, file: file});
      }
    },
    del(id) {
      this.$confirm(
          "Вы точно хотите удалить метод оплаты",
          "Внимание",
          "question"
      ).then(() => {
        this.$store.dispatch("payments/delete", id);
      })
    }
  }
}
</script>
